.ant-form-inline.search-form {
    .ant-form-item {
        margin-bottom: 15px;
    }
}
.ant-form-item {
    .ant-form-item-explain {
        display: none;
    }
}
.ant-form-item.ant-form-item-with-help {
    margin-bottom: @form-item-margin-bottom;
}
.ant-table{
    .ant-checkbox-wrapper{
        &:after{
            display: none;
        }
    }

    .ant-checkbox{
        display: inline-block;
    }
}