.reuse-tab {
    height: 50px;
    line-height: 50px;
    z-index: 2;
    background: @body-bg;
    margin: 0 15px;
    &-con {
        display: flex;
        align-items: center;
        overflow-x: auto;
    }
    &-tag {
        position: relative;
        padding-right: 20px;
        font-size: 12px;
        cursor: pointer;
        white-space: nowrap;
        &.active {
            color: @primary-color;
        }
        span{
            display: inline-block;
        }

        .anticon {
            margin-left: 5px;
            &:hover {
                color: @primary-color;
            }
        }
    }
}// ============================== Antd ============================ // 
@primary-color: #096dd9;
@form-item-margin-bottom: 12px;
@input-height-base: 40px;
@height-base: 40px;
@border-radius-base: 5px;
@font-family: Roboto,Arial,"Microsoft YaHei",Helvetica;
@font-size-base: 14px;

@card-head-padding: 15px;
@card-head-height: 36px;
@card-head-font-size: 14px;
@card-padding-base: 15px;
@card-radius: 10px;

@table-padding-vertical: 8px;
@descriptions-item-padding-bottom: 5px;

// ============================== custom ============================ // 
@dark-color: #34495E;
@primary: @primary-color;
@primary-dark:  darken(@primary, 15%);
@success:#2B9F5A;
@danger: #DE1919;
@color-font:#fff;
@gray:#999;
@h-color:#333;
@font-base:14px;
@border-color:#d9d9d9;
@border-radius:10px;
@border-dark:#BBBBBB;
@body-bg:#F5F6FA;
@body-color:#545454;
@info: #F5B81C;


@layout-header-bg: #fff; 
@layout-side-bg: #393F4E; 
@layout-side-color:#c8d1ea;
@layout-side-active:#fff;
@layout-side-pd: 22px; 

@table-bd:#f0f0f0;
@table-th-bg:#fafafa;

@statics-bg:#f5f5f5;
