@font-face {
  font-family: "iconfont"; /* Project id 2565092 */
  src: url('iconfont.woff2?t=1621683475332') format('woff2'),
       url('iconfont.woff?t=1621683475332') format('woff'),
       url('iconfont.ttf?t=1621683475332') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e781";
}

.icon-shouqi:before {
  content: "\e6ac";
}

.icon-zhankai:before {
  content: "\e6b4";
}

.icon-Notification:before {
  content: "\e642";
}

.icon-guzhang:before {
  content: "\e62c";
}

.icon-ren:before {
  content: "\e63d";
}

.icon-yuyue:before {
  content: "\e61a";
}

.icon-kehu:before {
  content: "\e67e";
}

.icon-yuangongxinxi:before {
  content: "\e60a";
}

