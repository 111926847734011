.layout-wrap{
    min-width: 1000px;
    background: none;
    height: 100%;
}
.layout{
    background: #F4F5F7;
    height: 100%;
}
.layout-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    z-index: 2;
    justify-content: space-between;
    margin:0 15px;
    border-bottom:1px solid rgba(0,0,0,.05);
    .layout-icon{
        background: #fff;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 20px;
        color:#A4AFB5;
        border-radius: @border-radius;
        cursor: pointer;
    }
}
.layout-container{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.layout-container-con{
    margin: 0 15px;
}
.layout-container-box{
    flex: auto;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 15px;
}
.layout-side {
    overflow: hidden;
    backface-visibility: hidden;
    text-align: center;
    z-index: 2;
    background:@primary;
}
.layout-menu-hide{
    .layout-side {
        display: none;
    }
   
}
.layout-left{
    display: flex;
    align-items: center;
    .layout-icon{
        color: @primary;
    }
}
.logo{
    text-align: center;
    padding:20px 0;
    margin: 0 @layout-side-pd 20px;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    border-bottom: 1px solid rgba(255,255,255,.2);
    img{
        height: 50px;
    }
}
.side-nav{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .ant-menu-inline{
        border:none;
        background: none;
        .uv-menu-item-selected{
            &:after{
                opacity:0
            }
        }
    }
    .ant-menu-submenu-title{
        padding: 0 @layout-side-pd!important;
    }
    .ant-menu-submenu:hover .ant-menu-submenu-arrow,.ant-menu-item-selected,.ant-menu-submenu-selected,.ant-menu-submenu-active,.ant-menu-submenu-title:hover,.ant-menu-item:hover{
        color:@layout-side-active!important;
    }
    .ant-menu-submenu-arrow{
        color:@layout-side-color;
        right: @layout-side-pd;
        &:after,&:before{
            height: 1px;
        }
    }
    .ant-menu{
        color:@layout-side-color;
       
        .ant-menu-item{
            background:none!important;
        }
        .ant-menu-sub.ant-menu-inline{
            padding: 0 0 0 53px;
        }
    }
    .ant-menu-sub.ant-menu-inline>.ant-menu-item{
        padding: 0!important;
        position: relative;
        &:after{
            position: absolute;
            content: "";
            height: 24px;
            width: 3px;
            background:@layout-side-active;
            transform: translate(-50%, -50%);
            top: 50%;
            right: 2px;
            transition:none;
            border:none;
            border-radius: 3px;
        }
    }
    .iconfont{
        margin-right: 5px;
        font-size: 16px;
        width: 20px;
        display: inline-block;
    }

}


.layout-h{
    margin-left: 15px;
    i{
        font-size: 24px;
        font-weight: normal;
        vertical-align: middle;
        color: #F45C3B;
        margin-right: 3px;
    }
}
.layout-link{
    color: @body-color;
}
.layout-user{
    margin-left: 15px;
    font-size: 30px;
}
.layout-right{
    display: flex;
    align-items: center;
}
.layout-language{
    margin-left: 15px;
}// ============================== Antd ============================ // 
@primary-color: #096dd9;
@form-item-margin-bottom: 12px;
@input-height-base: 40px;
@height-base: 40px;
@border-radius-base: 5px;
@font-family: Roboto,Arial,"Microsoft YaHei",Helvetica;
@font-size-base: 14px;

@card-head-padding: 15px;
@card-head-height: 36px;
@card-head-font-size: 14px;
@card-padding-base: 15px;
@card-radius: 10px;

@table-padding-vertical: 8px;
@descriptions-item-padding-bottom: 5px;

// ============================== custom ============================ // 
@dark-color: #34495E;
@primary: @primary-color;
@primary-dark:  darken(@primary, 15%);
@success:#2B9F5A;
@danger: #DE1919;
@color-font:#fff;
@gray:#999;
@h-color:#333;
@font-base:14px;
@border-color:#d9d9d9;
@border-radius:10px;
@border-dark:#BBBBBB;
@body-bg:#F5F6FA;
@body-color:#545454;
@info: #F5B81C;


@layout-header-bg: #fff; 
@layout-side-bg: #393F4E; 
@layout-side-color:#c8d1ea;
@layout-side-active:#fff;
@layout-side-pd: 22px; 

@table-bd:#f0f0f0;
@table-th-bg:#fafafa;

@statics-bg:#f5f5f5;
