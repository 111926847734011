*::-webkit-scrollbar {
	width: 6px;
	height: 8px;
}
*::-webkit-scrollbar-track {
	background: transparent;
}
*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.2);
}
*:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
}
	
body {
    background-image: linear-gradient(-45deg,#B5DBFB,#CDE1FF,#BFDEFE);
    color:@body-color;
}
#root{
    height: 100%;
}
.t-c{
	text-align: center;
}
.fz12{
    font-size: 12px;
}
.fz16{
    font-size: 16px!important;
}
.icon-fz{
    font-size: 18px;
}
.mr10{
    margin-right: 10px;
}
.nowrap{
    white-space: nowrap;
}
.link{
    color:@primary;
    cursor: pointer;
    white-space:nowrap;
    display: inline;
}
.link-line{
	.link;
	text-decoration: underline;
    &:hover{
        text-decoration: underline;
    }
}
.primary{
    color: @primary;
}
.success,.green{
    color: @success;
}
.danger,.red{
    color: @danger;
    &:hover{
        color: @danger;
    }
}
.gray{
    color:@gray;
}
.info{
	color:@info;
}

.table-action{
    white-space:nowrap;
    .link,.modal-btn{
        &+.link,&+a{
            margin-left: 15px;
        }
    }
    .anticon,.iconfont{
        font-size: 18px;
    }
}
.btn-lg{
    width: 120px;
}

.fc-file-li{
    margin-bottom: 10px;
}
.c-file-del{
    color:red;
    cursor: pointer;
}

.com-page-error {
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;

	&__wrapper {
		flex: 1;
		text-align: center;
		h2{
			margin-bottom: 20px;
		}
		.ant-btn {
			margin: 0 10px;
		}
	}
}

.upload-plug {
	&-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-btn {
		padding: 10px 0 15px;
	}
}

.upload-file {
	background: #EFF4F7;
	display: inline-block;
	margin: 10px 10px 0 0;
	padding: 0 5px 0 10px;
	border-radius: 10px;

	a {
		color: @primary-color;
	}
}

.upload-close {
	color: @danger;
	font-size: 12px;
	padding: 0 6px;
}

.upload-btn {
	width: 80px;
	height: 80px;
	text-align: center;
	line-height: 80px;
	border: 1px solid @border-dark;
	display: inline-block;
	font-size: 24px;
	cursor: pointer;
	color: @border-dark;
}

.upload-img {
	width: 80px;
	height: 80px;
	position: relative;

	img {
		width: 100%;
		height: 100%;
	}

	.anticon-close {
		position: absolute;
		top: -6px;
		right: -6px;
		background: @danger;
		color: #fff;
		border-radius: 50%;
		padding: 3px;
	}
}
.common-tit{
	padding: 6px 0;
	margin: 10px 0 20px;
	border-bottom:1px solid  @border-color;
	position: relative;
	padding-left: 10px;
	display: flex;
	justify-content: space-between;
	strong{
		line-height: @height-base;
	}
	&:before{
		content: "";
		position: absolute;
		border-left:3px solid  @primary;
		height: 12px;
		left: 0;
		top: 50%;
		transform: translateY(-50%)
	}
}