.login{
    height: 100vh;
}
.login-bg{
    background: url("./bg.jpg") no-repeat;
    background-size: cover;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.login-box{
    z-index: 2;
    position: absolute;
    opacity: 1;
    background:#fff;
    width: 666px;
    left:50%;
    top: 50%;
    height: 380px;
    transform: translate(-50%, -50%);
    display: flex;
    box-shadow: 0 5px 10px 5px rgba(0,0,0,8%), 0 6px 10px 6px rgba(0,0,0,5%);
}
.login-l{
    width: 50%;
    background:@primary;
    text-align: center;
    color: #fff;
}
.login-r{
    width: 50%;
    .ant-form{
        padding:0 40px;
        .ant-form-item{
            margin-bottom: 18px;
        }
    }
}
.login-title{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding: 36px 0 22px;
}
.login-btn{
    width: 100%;
    margin-top: 10px;
    height: 50px;
}
.login-input{
    height: 50px;
}
.login-h{
    font-size: 26px;
    color:#fff;

}
.login-logo{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 100px 0 20px;
}

















// ============================== Antd ============================ // 
@primary-color: #096dd9;
@form-item-margin-bottom: 12px;
@input-height-base: 40px;
@height-base: 40px;
@border-radius-base: 5px;
@font-family: Roboto,Arial,"Microsoft YaHei",Helvetica;
@font-size-base: 14px;

@card-head-padding: 15px;
@card-head-height: 36px;
@card-head-font-size: 14px;
@card-padding-base: 15px;
@card-radius: 10px;

@table-padding-vertical: 8px;
@descriptions-item-padding-bottom: 5px;

// ============================== custom ============================ // 
@dark-color: #34495E;
@primary: @primary-color;
@primary-dark:  darken(@primary, 15%);
@success:#2B9F5A;
@danger: #DE1919;
@color-font:#fff;
@gray:#999;
@h-color:#333;
@font-base:14px;
@border-color:#d9d9d9;
@border-radius:10px;
@border-dark:#BBBBBB;
@body-bg:#F5F6FA;
@body-color:#545454;
@info: #F5B81C;


@layout-header-bg: #fff; 
@layout-side-bg: #393F4E; 
@layout-side-color:#c8d1ea;
@layout-side-active:#fff;
@layout-side-pd: 22px; 

@table-bd:#f0f0f0;
@table-th-bg:#fafafa;

@statics-bg:#f5f5f5;
